<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12" v-if="loading">
                <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
            </div>
            <div class="col-lg-12" v-else>
                <div class="card">
                    <div class="card-body">
                        <b-card-title>
                            <h4 class="card-title">User Professions </h4>
                        </b-card-title>
                        <div class="row">
                            <div class="col-3">
                                <FormulateForm name="createItem" v-model="values" :schema="searchScheme"
                                    @submit="updateItem">

                                    <FormulateInput type="submit">
                                        <span v-if="updatingInfo">
                                            <b-spinner class="vueformulate-loader"></b-spinner>
                                            <span> Updating... </span>
                                        </span>
                                    </FormulateInput>
                                </FormulateForm>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { user_professions } from "@/config/api/user_professions";
import { getFormData } from "@/utils/createForm";
import Swal from "sweetalert2";
import router from "@/router/index";

export default {
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            loading: false,
            title: "Professions",
            file: undefined,
            professionId: "",
            items: [
                {
                    text: "User Professions ",
                    to: { name: "User Professions " },
                },
                {
                    text: "Edit",
                    active: true,
                },
            ],

            updatingInfo: false,
            values: {},
            searchScheme: [
                {
                    index: 1,
                    type: "text",
                    name: "name",
                    label: "Name English",
                    "validation-messages": { required: "Profession name is required" },
                    validation: "required",
                },
                {
                    index: 1,
                    type: "text",
                    name: "name_ar",
                    label: "Name Arabic",
                    "validation-messages": { required: "Profession name is required" },
                    validation: "required",
                },

            ],
        };
    },
    computed: {},
    watch: {},
    mounted() {
        if (this.$route.query.id) {
            this.loading = true;
            this.professionId = this.$route.query.id;
            this.searchProperty();
        }
    },
    methods: {
        updateItem(data) {
            this.updatingInfo = true;
            let api = undefined;

            let updateData = data;
            if (this.professionId) {
                api = { ...user_professions.update };
                updateData = {
                    ...updateData,
                    id: this.professionId,
                };
                api.url += this.professionId;
                // api.id = this.professionId;
            } else {

                api = { ...user_professions.create };
            }

            api.data = { ...updateData };

            this.generateAPI(api)
                .then(() => {
                    this.updatingInfo = false;
                    this.success();
                })
                .catch(() => {
                    this.updatingInfo = false;
                    this.failed();
                })
                .finally(() => {
                    setTimeout(() => {
                        this.searchProperty();
                        router.push({ path: "/user-professions" });
                    }, 1000);
                });
        },
        success() {
            Swal.fire({
                position: "center",
                icon: "success",
                title: "User Professions has been added",
                showConfirmButton: false,
                timer: 1000,
            });
        },
        failed() {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Please try again",
                showConfirmButton: false,
                timer: 1000,
            });
        },
        searchProperty() {
            if (!this.professionId) return;
            this.loading = true;
            let api = { ...user_professions.get };
            api.url += this.professionId;
            this.generateAPI(api)
                .then((res) => {
                    this.loading = false;
                    this.values = res.data.professions;
                })
                .catch((err) => {
                    alert("Failed");
                    console.log(err);
                });
        },
    },
};
</script>

